<template>
  <div class="aboutOurCompany">
    <div class="title"><h3>  المدرسة الفنيه </h3></div>

    <v-divider></v-divider>
    <v-container>
      <v-row>
        <h4 class="blue--text">
          شركه مياه الشرب والصرف الصحي بأسيوط والوادي الجديد
        </h4>
        <h4>/ المعرض</h4>
        <h4>/المدرسة الفنية</h4></v-row
      >
      <v-row justify="center" class="mt-10">
        <v-col
          v-for="(expandImg, i) in ImagesLab"
          :key="i"
          class="pa-1 ma-1"
          xs="8"
          sm="5"
          md="5"
          lg="3"
          cols="8"
        >
          <expandable-image
            class="Labimg scale-in-center"
            :src="expandImg"
            :lazy-src="loadingLink"
             
          />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
          
<script>
export default {
  data() {
    return {
     loadingLink:require("@/assets/Images/load.svg"),
      ImagesLab: [
          require("@/assets/Images/school_dep/105.jpg"),
        require("@/assets/Images/school_dep/106.jpg"),
        require("@/assets/Images/school_dep/107.jpg"),
        require("@/assets/Images/school_dep/108.jpg"),
        require("@/assets/Images/school_dep/109.jpg"),
        require("@/assets/Images/school_dep/110.jpg"),
        require("@/assets/Images/school_dep/96.jpg"),
        require("@/assets/Images/school_dep/97.jpg"),
        require("@/assets/Images/school_dep/98.jpg"),
        require("@/assets/Images/school_dep/99.jpg"),
        require("@/assets/Images/school_dep/100.jpg"),
        require("@/assets/Images/school_dep/101.jpg"),
        require("@/assets/Images/school_dep/102.jpg"),
        require("@/assets/Images/school_dep/103.jpg"),
        require("@/assets/Images/school_dep/104.jpg"),
     
     
     
      ],
    };
  },
};
</script>
<style>
.Labimg {
  box-shadow: gray 3px 3px 3px 3px;
}
</style>